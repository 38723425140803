.oo-up-next-panel {
  pointer-events: auto;
  position: relative;
  width: 100%;
  height: 80px;
  right: 0;
  background-color: rgba(22, 22, 22, 0.9);
  transition: bottom 0.5s;
  
  a.oo-up-next-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 140px;
    height: 100%;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    img.oo-content-image {
    @include absolute-center;
    max-width:100%;
    max-height:100%;
    width: auto;
    height: auto;
    }
    
    span.oo-icon {
      @include absolute-center;
      font-size: 25px;
      opacity: 1;
      color: white;
      transition: opacity .25s ease-in-out;
    }
  }


}

.oo-content-metadata {
  position: absolute;
  top: 0;
  left: 140px;
  width: 78%;
  height: 100%;
  
  .oo-up-next-title {
    position: absolute;
    display: flex;
    top: 3px;
    left: 15px;
    width: 100%;
    color: $white;

    .oo-up-next-count-down {
      width: 36px;
      height: 36px;
      float: left;
    }

    .oo-up-next-title-text {
      font-size: 18pt;
      font-family: $font-family-roboto-condensed;
      font-weight: bold;
      max-width: 70%;
      color: inherit;
      resize: none;
      display: inline-block;
      float: left;
    }
  }
  
  .oo-content-description {
    position: absolute;
    bottom: 5px;
    left: 15px;
    width: 70%;
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12pt;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
  }
}