// Reset
//
// Global resets to common HTML elements and more for easier usage by html5-skin.
// Adds additional rules on top of Normalize.css, including several overrides.


// Reset the box-sizing
//
// Change from `box-sizing: content-box` to `border-box` so that when you add
// `padding` or `border`s to an element, the overall declared `width` does not
// change. For example, `width: 100px;` will always be `100px` despite the
// `border: 10px solid red;` and `padding: 20px;`.
//
// Credit: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// https://github.com/twbs/bootstrap/blob/v4-dev/scss/_reboot.scss

*,
*::before,
*::after {
  box-sizing: border-box;
}


// Make viewport responsive
//
// @viewport is needed because IE 10+ doesn't honor <meta name="viewport"> in
// some cases. See http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/.
// Eventually @viewport will replace <meta name="viewport">. It's been manually
// prefixed for forward-compatibility.
//
// However, `device-width` is broken on IE 10 on Windows (Phone) 8,
// (see http://timkadlec.com/2013/01/windows-phone-8-and-device-width/ and https://github.com/twbs/bootstrap/issues/10497)
// and the fix for that involves a snippet of JavaScript to sniff the user agent
// and apply some conditional CSS.
//
// See http://getbootstrap.com/getting-started/#support-ie10-width for the relevant hack.


// Wrap `@viewport` with `@at-root` for when folks do a nested import (e.g.,
// `.class-name { @import "bootstrap"; }`).
//@at-root {
//  @-moz-viewport      { width: device-width; }
//  @-ms-viewport       { width: device-width; }
//  @-o-viewport        { width: device-width; }
//  @-webkit-viewport   { width: device-width; }
//  @viewport           { width: device-width; }
//}


//
// Typography
//

// Remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

// Abbreviations and acronyms
//abbr[title],
//  // Add data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257
//abbr[data-original-title] {
//  cursor: help;
//  border-bottom: 1px dotted $abbr-border-color;
//}
//
//address {
//  margin-bottom: 1rem;
//  font-style: normal;
//  line-height: inherit;
//}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

//dt {
//  font-weight: bold;
//}
//
//dd {
//  margin-bottom: .5rem;
//  margin-left: 0; // Undo browser default
//}
//
//blockquote {
//  margin: 0 0 1rem;
//}


//
// Code
//

//pre {
//  // Remove browser default top margin
//  margin-top: 0;
//  // Reset browser default of `1em` to use `rem`s
//  margin-bottom: 1rem;
//}


//
// Figures
//

figure {
  // Normalize adds `margin` to `figure`s as browsers apply it inconsistently.
  // We reset that to create a better flow in-page.
  margin: 0 0 1rem;
}



//
// Images
//

img {
  // By default, `<img>`s are `inline-block`. This assumes that, and vertically
  // centers them. This won't apply should you reset them to `block` level.
  vertical-align: middle;
}


// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
  cursor: pointer;
}


//
// Tables
//

//table {
//  // Reset for nesting within parents with `background-color`.
//  background-color: $table-bg;
//}
//
//caption {
//  padding-top: $table-cell-padding;
//  padding-bottom: $table-cell-padding;
//  color: $text-muted;
//  text-align: left;
//  caption-side: bottom;
//}

th {
  // Centered by default, but left-align-ed to match the `td`s below.
  text-align: left;
}


//
// Forms
//

label {
  // Allow labels can use `margin` for spacing.
  display: inline-block;
  margin-bottom: .5rem;
}

input,
button,
select,
textarea {
  // Remove all `margin`s so our classes don't have to do it themselves.
  margin: 0;
  // Normalize includes `font: inherit;`, so `font-family`. `font-size`, etc are
  // properly inherited. However, `line-height` isn't addressed there. Using this
  // ensures we don't need to unnecessarily redeclare the global font stack.
  line-height: inherit;
  // iOS adds rounded borders by default
  border-radius: 0;
}

textarea {
  // Textareas should really only resize vertically so they don't break their (horizontal) containers.
  resize: vertical;
}

//fieldset {
//  // Chrome and Firefox set a `min-width: min-content;` on fieldsets,
//  // so we reset that to ensure it behaves more like a standard block element.
//  // See https://github.com/twbs/bootstrap/issues/12359.
//  min-width: 0;
//  // Reset the default outline behavior of fieldsets so they don't affect page layout.
//  padding: 0;
//  margin: 0;
//  border: 0;
//}
//
//legend {
//  // Reset the entire legend element to match the `fieldset`
//  display: block;
//  width: 100%;
//  padding: 0;
//  margin-bottom: .5rem;
//  font-size: 1.5rem;
//  line-height: inherit;
//  //  border: 0;
//}

//input[type="search"] {
//  // Undo Normalize's default here to match our global overrides.
//  box-sizing: inherit;
//  // This overrides the extra rounded corners on search inputs in iOS so that our
//  // `.form-control` class can properly style them. Note that this cannot simply
//  // be added to `.form-control` as it's not specific enough. For details, see
//  // https://github.com/twbs/bootstrap/issues/11586.
//  -webkit-appearance: none;
//}