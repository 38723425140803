// Credit: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

//// Colors
$black:                     #000 !default;
$gray-dark:                 #373a3c !default;
$gray:                      #55595c !default;
$gray-light:                #818a91 !default;
$gray-lighter:              #afafaf !default;
$gray-lightest:             #f7f7f9 !default;
$blue-light:                #448aff !default;
$white:                     #fff !default;
$brand-primary:             $blue-light !default;
$body-color:                $white !default;
//
//
//// Spacing
////
//// Control the default styling of most elements by modifying these
//// variables. Mostly focused on spacing.
//
//$spacer:                     1em !default;
//$spacer-x:                   $spacer !default;
//$spacer-y:                   $spacer !default;
$spacer-close-btn:            -18px !default;
$border-width:                1px !default;
$panel-padding:               24.5px !default;
$panel-padding-lg:            35px !default;
//
//
//// Background Colors
////
//
$body-bg:                    #000 !default;
$panel-bg:                   rgba($black, 0.7) !default;
$control-bar-bg:             rgba($black, 0.3) !default;
$text-track-bg:              rgba($black, 0.8) !default;
$ad-top-bar-bg:              rgba($black, 0.5) !default;
$popover-bg:                 rgba(26, 26, 26, 0.8) !default;
$scrollbar-bg:               #545456 !default;
//
//
//// Links
////
//// Style anchor elements.
//
$link-color:                 $brand-primary !default;
$link-decoration:            none !default;
$link-hover-color:           darken($link-color, 15%) !default;
$link-hover-decoration:      none !default;
//
//
//// Typography
////
//// Font, line-height, and color for body text, headings, and more.
//
$font-family-sans-serif:            "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-roboto:                "Roboto", sans-serif !default;
$font-family-roboto-condensed:      "Roboto Condensed", sans-serif !default;
$font-family-roboto-bold:           "Roboto Bold", sans-serif !default;
$font-family-roboto-condensed-bold: "Roboto Condensed Bold", sans-serif !default;
$font-family-verdana:               Verdana, Geneva, sans-serif !default;
//$font-family-serif:               Georgia, "Times New Roman", Times, serif !default;
//$font-family-monospace:           Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:                  $font-family-sans-serif !default;
//
//// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root:               16px !default;
$font-size-base:               1em !default;
//$font-size-lg:               1.25em !default;
$font-size-lg-screen-title:    1.9em !default;
$font-size-lg-screen-desc:     1.3em !default;
$font-size-md-screen-title:    1.6em !default;
$font-size-md-screen-desc:     1.1em !default;
$font-size-sm-screen-title:    1.1em !default;
$font-size-sm-screen-desc:     0.8em !default;
//$font-size-sm:               .85em !default;
//$font-size-xs:               .75em !default;
//
$line-height:                1.5 !default;
//
$border-radius:          .25em !default;
//
//
//// Forms
//
$input-padding-x:                .75em !default;
$input-padding-y:                .375em !default;
//
$input-bg:                       #fff !default;
$input-bg-disabled:              $gray-lighter !default;
//
$input-color:                    $gray !default;
$input-border:                   #ccc !default;
$input-box-shadow:               inset 0 1px 1px rgba(0,0,0,.075) !default;
//
$input-border-radius:            $border-radius !default;
//
$input-color-placeholder:        #999 !default;
//
$cursor-disabled:                not-allowed !default;
//
//// Form validation icons
//$form-icon-success: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNoZWNrIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDYxMiA3OTIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYxMiA3OTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiM1Q0I4NUMiIGQ9Ik0yMzMuOCw2MTAuMWMtMTMuMywwLTI1LjktNi4yLTM0LTE2LjlMOTAuNSw0NDguOEM3Ni4zLDQzMCw4MCw0MDMuMyw5OC44LDM4OS4xYzE4LjgtMTQuMyw0NS41LTEwLjUsNTkuOCw4LjNsNzEuOSw5NWwyMjAuOS0yNTAuNWMxMi41LTIwLDM4LjgtMjYuMSw1OC44LTEzLjZjMjAsMTIuNCwyNi4xLDM4LjcsMTMuNiw1OC44TDI3MCw1OTBjLTcuNCwxMi0yMC4yLDE5LjQtMzQuMywyMC4xQzIzNS4xLDYxMC4xLDIzNC41LDYxMC4xLDIzMy44LDYxMC4xeiIvPjwvc3ZnPg==" !default;
//$form-icon-warning: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9Ildhcm5pbmciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNjEyIDc5MiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjEyIDc5MiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZmlsbD0iI0YwQUQ0RSIgZD0iTTYwMyw2NDAuMmwtMjc4LjUtNTA5Yy0zLjgtNi42LTEwLjgtMTAuNi0xOC41LTEwLjZzLTE0LjcsNC4xLTE4LjUsMTAuNkw5LDY0MC4yYy0zLjcsNi41LTMuNiwxNC40LDAuMiwyMC44YzMuOCw2LjUsMTAuOCwxMC40LDE4LjMsMTAuNGg1NTcuMWM3LjUsMCwxNC41LTMuOSwxOC4zLTEwLjRDNjA2LjYsNjU0LjYsNjA2LjcsNjQ2LjYsNjAzLDY0MC4yeiBNMzM2LjYsNjEwLjJoLTYxLjJWNTQ5aDYxLjJWNjEwLjJ6IE0zMzYuNiw1MDMuMWgtNjEuMlYzMDQuMmg2MS4yVjUwMy4xeiIvPjwvc3ZnPg==" !default;
//$form-icon-error: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNyb3NzIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDYxMiA3OTIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYxMiA3OTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiNEOTUzNEYiIGQ9Ik00NDcsNTQ0LjRjLTE0LjQsMTQuNC0zNy42LDE0LjQtNTEuOSwwTDMwNiw0NTEuN2wtODkuMSw5Mi43Yy0xNC40LDE0LjQtMzcuNiwxNC40LTUxLjksMGMtMTQuNC0xNC40LTE0LjQtMzcuNiwwLTUxLjlsOTIuNC05Ni40TDE2NSwyOTkuNmMtMTQuNC0xNC40LTE0LjQtMzcuNiwwLTUxLjlzMzcuNi0xNC40LDUxLjksMGw4OS4yLDkyLjdsODkuMS05Mi43YzE0LjQtMTQuNCwzNy42LTE0LjQsNTEuOSwwYzE0LjQsMTQuNCwxNC40LDM3LjYsMCw1MS45TDM1NC43LDM5Nmw5Mi40LDk2LjRDNDYxLjQsNTA2LjgsNDYxLjQsNTMwLDQ0Nyw1NDQuNHoiLz48L3N2Zz4=" !default;
//
//
// Z-index master list
//
// Values sorted in ascending order for bird's eye view.
// Components dependent on z-index are designed to work together
//
$zindex-player-skin-plugins-blur:        -10000 !default;
$zindex-closed-caption-overlay:              -1 !default;
$zindex-playhead-padding:                     1 !default;
$zindex-scrollbar:                         9999 !default;
$VIDEO_Z_INDEX:                           10000 !default; //in core, here for bird's eye view tracking
$zindex-discovery-count-down-icon:        10005 !default;
$OVERLAY_Z_INDEX:                         10500 !default; //in core, here for bird's eye view tracking
$ALICE_SKIN_Z_INDEX:                      11000 !default; //in core, here for bird's eye view tracking
$zindex-player-skin:                      11000 !default;
$zindex-action-icon-pause-hidden:         11990 !default;
$zindex-player-skin-plugins:              12000 !default;
$zindex-start-screen:                     12005 !default;
$zindex-player-skin-plugins-click-layer:  12005 !default;
$zindex-ad-screen-panel-click-layer:      12005 !default;
$zindex-control-bar:                      12500 !default;
$zindex-scrubber-bar:                     12600 !default;
$zindex-action-icon-pause-animate:        12600 !default;
$zindex-ad-top-bar:                       12600 !default;
$zindex-popover:                          12600 !default;
$SUPER_Z_INDEX:                           20000 !default; //in core, here for bird's eye view tracking
$zindex-fullscreen:                  2147483647 !default; //in screenfull, here for bird's eye view tracking

//// Form states and alerts
////
//// Define colors for form feedback states and, by default, alerts.
//
$state-danger-text:              #a94442 !default;
//$state-danger-bg:                #f2dede !default;
//$state-danger-border:            darken($state-danger-bg, 5%) !default;
//
//
// Multiples
$xsmall-multiple:                 0.7 !default;
$large-multiple:                  1.2 !default;

// Video
$control-bar-height:              90px !default;
$control-bar-height-small:        57px !default;
$scrubber-bar-height:             6px !default;
$scrubber-bar-padding-height:     34px !default;
$scrubber-bar-left-right-padding: 15px !default;
$min-player-width:                320px !default;

//slider
$thumb-color:                     yellow !default;
$progress-color:                  green !default;
$track-color:                     red !default;
$slider-width:                    10px !default;
$slider-height:                   1px !default;
$slider-thumb-diameter:           3px !default;

$slider-volume-width:             200px !default;
$slider-caption-opacity-width:    100% !default;
$slider-volume-height:            3px !default;
$slider-volume-thumb-diameter:    15px !default;
$slider-volume-thumb-color:       #FFFFFF !default;
$slider-volume-progress-color:    #FFFFFF !default;
$slider-volume-track-color:       #999999 !default;

$slider-volume-width-small:           $slider-volume-width * $xsmall-multiple !default;
$slider-volume-height-small:          $slider-volume-height * $xsmall-multiple !default;
$slider-volume-thumb-diameter-small:  $slider-volume-thumb-diameter * $xsmall-multiple !default;

//bitrate
$bitrate-horizontal-height:        100px !default;
$popover-height:                   80px !default;

$font-path:                       "assets/fonts" !default;

//gradients
$control-bar-gradient: linear-gradient(to bottom, rgba(0,0,0,0) 23%,rgba(0,0,0,0.6) 90%,rgba(0,0,0,0.69) 100%);

//transition - easing
$medium-transition: transition, opacity .2s ease-in-out, height .3s ease-in-out;

//watermark
$watermark-margin: 15px;
