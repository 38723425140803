// oo- prefix excluded, external component from react-scrollbar

.scrollarea-content{
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.scrollarea{
  position: relative;
  overflow: hidden;
  .scrollbar-container{

    &.horizontal{
      width: 100%;
      height: 10px;
      left: 0;
      bottom: 0;

      .scrollbar{
        width: 20px;
        height: 2px;
        background: $scrollbar-bg;
        margin-top: 4px;
        border-radius: 10px;
      }
    }

    &.vertical{
      width: 10px;
      height: 100%;
      right: 0;
      top: 0;

      .scrollbar{
        width: 2px;
        height: 20px;
        background: $scrollbar-bg;
        margin-left: 4px;
        border-radius: 10px;
      }
    }

    position: absolute;
    background: none;
    opacity: .4;
    z-index: $zindex-scrollbar;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;

    &:hover{
      //background: gray;
      opacity: 1 !important;
    }

    &.active{
      //background: gray;
      opacity: 1 !important;
    }
  }

  &:hover .scrollbar-container{
    opacity: 0.8;
  }
}