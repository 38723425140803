.oo-spinner-screen {
  @extend .oo-state-screen;
  pointer-events: none; // Allow click to pass through
}

.oo-spinner-wrapper {
  @extend .oo-center-vertical-horizontal;

  .oo-spinner {
    width:70px;
    height:70px;
    opacity: 1;
    color: white;
    transition: opacity .25s ease-in-out;
    cursor: pointer;
    -webkit-animation: spin 0.6s linear infinite;
    -moz-animation: spin 0.6s linear infinite;
    animation: spin 0.6s linear infinite;
  }
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}