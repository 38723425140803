.oo-share-panel {
  font-size: em(14px);
  .oo-tab-row {
    line-height: 1;

    .oo-tab {
      display: inline-block;
      color: #afafaf;
      font-weight: bold;
      padding-right: 25px;
      &:last-child {
        padding-left: 0;
      }
    }

    .oo-share-tab {
      @extend .oo-tab
    }

    .oo-embed-tab {
      @extend .oo-tab
    }

    .oo-active {
      color: $white;
    }
  }
}

.oo-share-tab-panel {
  @include absolute-center;
  color: $white;
  text-align: center;
  width: 90%;
  margin-top: 10px;

  a {
    color: inherit;
    &:hover {
      color: $white;
    }
  }

  .oo-social-action-text {
    font-family: $font-family-roboto-condensed;
    font-size: em(20px);
    font-weight: bold;
    margin-bottom: 15px;
  }

  .oo-social-icon {
    display: inline-block;
    height: 38px;
    width: 38px;
    margin-bottom: $panel-padding/2;
    margin-right: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 6px;
    &:last-child {
      margin-right: 0;
    }
  }

  .oo-facebook {
    @extend .oo-social-icon;
    background-image: url("//player.ooyala.com/static/v4/stable/4.11.13/skin-plugin/assets/images/social-facebook.svg");
  }

  .oo-twitter {
    @extend .oo-social-icon;
    background-image: url("//player.ooyala.com/static/v4/stable/4.11.13/skin-plugin/assets/images/social-twitter.svg");
  }

  .oo-google-plus {
    @extend .oo-social-icon;
    background-image: url("//player.ooyala.com/static/v4/stable/4.11.13/skin-plugin/assets/images/social-google.svg");
  }

  .oo-email-share {
    @extend .oo-social-icon;
    background-image: url("//player.ooyala.com/static/v4/stable/4.11.13/skin-plugin/assets/images/social-email.svg");
    background-size: cover;
  }

  .oo-embed-form {
    resize: none;
    height: calc(15vh);
  }
}
