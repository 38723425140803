.oo-ad-screen-panel {
  position: absolute;
  top: 0;
  bottom: 64px;
  width: 100%;

  .oo-ad-screen-panel-click-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zindex-ad-screen-panel-click-layer;
  }

  .oo-ad-top-bar {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    top: 0;
    height: 25px;
    width: 100%;
    background-color: $ad-top-bar-bg;
    margin: 0;
    font-size: 1em;
    list-style: none;
    flex-flow: row nowrap;
    justify-content: flex-start;
    @include vendor-prefixes(user-select, none);
    transition: bottom 0.5s, height 0.5s;
    z-index: $zindex-ad-top-bar;

    .oo-ad-title, .oo-ad-playback-info {
      font-size: 0.9em;
      color: $white;
      font-family: $font-family-roboto;
      margin: auto;
      text-align: center;
      padding-left: 7.5px;
      padding-right: 7.5px;
      white-space: pre;
    }

    .oo-flexible-space {
      @include vendor-prefixes(flex, 1);
    }

    .oo-learn-more {
      font-size: 0.9em;
      font-family: $font-family-roboto;
      color: $white;
      margin: auto 15px auto auto;
      text-align: center;
      cursor: pointer;

      .oo-button-icon {
        font-size: 0.7em;
        margin-right: 5px
      }
    }

    .oo-learn-more.oo-hidden {
      visibility: hidden;
    }

    .oo-skip-button {
      font-size: 0.9em;
      font-family: $font-family-roboto;
      color: $white;
      visibility: hidden;
      width: 0;
      margin: auto auto auto 0;
      opacity: 0.3;
      cursor: default;

      .oo-button-icon {
        font-size: 0.7em;
        margin-right: 5px;
      }
    }

    .oo-skip-button.oo-visible {
      visibility: visible;
      width: auto;
      margin: auto 15px;
      text-align: center;
      cursor: pointer;

      .oo-skip-icon {
        font-size: 0.7em;
        margin-right: 5px;
      }
    }

    .oo-skip-button.oo-enabled {
      opacity: 1;
      cursor: pointer;
    }
  }
}
